<template>
  <content-wrapper>
    <Grid gap="20px">
      <div class="carousel-container">
        <home-carousel />
        <p class="carousel-footer">
          We're here to find what you need, and to help you sell what you don't need.
        </p>
      </div>
      <div>
        <h2>Our Mission:</h2>
        <p class="home-pg">
          Here at Narrow Gate, we are dedicated to finding you the
          best price for the quality products you need. Our group of
          professionals is willing to go the extra mile to find exactly what you
          need. You can call on us for all of your pallet, lumber, cardboard, &amp;
          plastic needs. Or perhaps you need help selling your products, we've
          got the connections for that as well. We want to take the burden off
          of you so that you can spend more time focusing on your product. We
          find customers for you, so that you don't have to.
        </p>
      </div>
    </Grid>
  </content-wrapper>
</template>

<script>
import ContentWrapper from '@/components/ContentWrapper';
import Grid from '@/components/Grid';
import HomeCarousel from '@/components/HomeCarousel';

export default {
  name: 'Home',
  components: {
    ContentWrapper,
    Grid,
    HomeCarousel,
  },
};
</script>

<style lang="scss" scoped>
.carousel-container {
  max-width: 100%;

  .carousel-footer {
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    padding: 12px;
  }
}

.home-pg {
  font-size: 24px;
  line-height: 1.4em;
  padding: 12px 25px 12px 5px;
}
</style>
