<template>
  <div
    class="grid"
    :style="`grid-gap: ${gap}; grid-template-columns: repeat(auto-fit, minmax(${minmax}, 1fr));`"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Grid',
  props: {
    gap: {
      type: String,
      default: '25px',
    },
    minmax: {
      type: String,
      default: '300px',
    },
  },
};
</script>

<style lang="scss" scoped>
  .grid {
    display: grid;
    grid-auto-flow: dense;
    justify-items: center;
  }
</style>
